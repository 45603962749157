<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路编号">
				<el-input class="el_input" v-model="form.bl_num" placeholder="线路编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="上游编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="组别编号">
				<el-input class="el_input" v-model="form.group1_num" placeholder="组别编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="车牌号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="磅单编号">
				<el-input class="el_input" v-model="form.file_num" placeholder="磅单编号搜索" clearable></el-input>
			</el-form-item>
	
			<!-- <el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="已支付" value="3"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settled_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未结算" value="1"></el-option>
					<el-option label="部分结算" value="2"></el-option>
					<el-option label="结算完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.i_status" clearable>
					<el-option label="不限" value="0"></el-option>
					<el-option label="未开票" value="1"></el-option>
					<el-option label="开票中" value="2"></el-option>
					<el-option label="已开票" value="3"></el-option>
					<el-option label="已驳回" value="4"></el-option>
				</el-select>
			</el-form-item>
			
			
			<el-form-item class="el_form_item" label="装货地">
				<el-cascader class="el_input" v-model="form.case_cities" @change="ser_para_case_cities_change" placeholder="装货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="到货地">
				<el-cascader class="el_input" v-model="form.aim_cities" @change="ser_para_aim_cities_change" placeholder="到货地省市区" :options="ser_para.cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="运费设置">
				<el-select class="el_input" v-model="form.is_set_freight" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="锁定状态">
				<el-select class="el_input" v-model="form.lock_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="已锁定" value="1"></el-option>
					<el-option label="未锁定" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单备注">
				<el-input class="el_input" v-model="form.mark" placeholder="运单备注" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="保障服务费">
				<el-select class="el_input" v-model="form.is_set_insure" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="子账户" >
				<el-select class="el_input"
					v-model="form.sub_user_nums"
					multiple
					filterable
					remote
					reserve-keyword
					placeholder="请输入子账户信息"
					@focus="sub_user"
					:loading="sub_user_loading">
					<el-option
					v-for="item in sub_user_list"
					:key="item.value"
					:label="item.label"
					:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="title">
				<el-autocomplete
					class="inline-input"
					v-model="form.bl_name"
					:fetch-suggestions="querySearch"
					:placeholder="title"
					@focus="bl_focus"
				></el-autocomplete>
				<!-- <el-input class="el_input" v-model="form.bl_name" :placeholder='title' clearable></el-input> -->
				<!-- <el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="需要回程" value="1"></el-option>
					<el-option label="不需回程" value="2"></el-option>
				</el-select> -->
			</el-form-item>
			<el-form-item class="el_form_item" label="项目名称">
				<el-input class="el_input" v-model="form.item_name" placeholder="项目名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否垫资">
				<el-select class="el_input" v-model="form.is_yufu" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否回款">
				<el-select class="el_input" v-model="form.is_admin_pay_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="">
			</el-form-item>
			<el-form-item class="el_form_item" label="">
			</el-form-item> -->


			
		    <el-form-item class="el_form_item elfrom_item" label="发到时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item" label="">
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="建单时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr1"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item class="el_form_item">

			</el-form-item>
			<el-form-item label-width="0" style="margin-left:-10px">
				<el-button type="primary" class="btn1" @click="page_ser">查询</el-button>
				<el-button type="primary" class="btn" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" class="btn" @click="tords_start">批量发车</el-button>
				<el-button type="success" class="btn" @click="tords_arrived">批量到货</el-button>
				<el-button type="success" class="btn1" @click="tords_pay_open('批量支付运费')">批量支付</el-button>
				<el-button type="success" class="btn1" @click="tords_pay_open('批量请款')">批量请款</el-button>
				<el-button type="success" class="btn1" @click="tords_invoice_apply">批量申请开票</el-button>
				<el-button type="success" class="btn1" @click="tords_freight_check">运费核对</el-button>
				<el-button type="success" class="btn" @click="tord_whole_excle">全部导出</el-button>
				<el-button type="success" class="btn" @click="batch_download_voucher">批量下载凭证</el-button>
				<el-button type="success" class="btn" @click="set_truck_owner">设置收款人</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<div> 
				<span>共:{{list.total}}条</span>
				<span>总运费:{{list.total_freight}}元</span>
				<span>服务费:{{list.total_service}}元</span>
				<span>总计:{{list.total_much}}元</span>
				<span>总吨数:{{list.total_cargo_weight}}吨</span>
			</div>
			<div style="margin-top: 10px">
				<span v-if="tick_freight>0">勾选总运费:{{tick_freight}}元</span>
				<span v-if="service_charge>0">勾选总服务费:{{service_charge}}元</span>
				<span v-if="freight_total>0">勾选总计:{{freight_total}}元</span>
				<span v-if="total_cargo_weight>0">勾选总吨数:{{total_cargo_weight}}吨</span>
			</div>
			
		</div>
		<template>
			<el-radio-group
				v-model="form.status"
				style="margin-bottom: 30px"
				@change="top_change"
			>
				<el-radio-button label="">全部</el-radio-button>
				<el-radio-button label="1">未发车</el-radio-button>aw
				<el-radio-button label="2">已发车</el-radio-button>
				<el-radio-button label="3">已到货</el-radio-button>
			</el-radio-group>
			<el-radio-group
				v-model="form.payed_status"
				style="margin-bottom: 30px;margin-left:30px"
				@change="top_change"
			>
				<el-radio-button label="">全部</el-radio-button>
				<el-radio-button label="1">未支付</el-radio-button>
				<el-radio-button label="2">部分支付</el-radio-button>
				<el-radio-button label="3">已支付</el-radio-button>
				<!-- <el-radio-button label="4">未支付</el-radio-button>
				<el-radio-button label="5">部分支付</el-radio-button>
				<el-radio-button label="6">已支付</el-radio-button> -->
			</el-radio-group>
		</template>
		<!-- 表格 -->
		<div :class="tab_height">
			<el-table
				size="small"
				height="100%"
				:data="list.data"
				:border="true"
				:stripe="true"
				v-loading="list.loading"
				@selection-change="tords_list_choose"
			>
				<el-table-column type="selection" width="39px"></el-table-column>
				<el-table-column label="运单编号/组别编号/上游单号" width="220px">
					<template slot-scope="scope">

						<div class="tab_line_item">运单编号:{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">线路编号:{{scope.row.bl_num?scope.row.bl_num:'无'}}</div>
						<div class="tab_line_item">线路名称:{{scope.row.bl_info.bl_name?scope.row.bl_info.bl_name:'无'}}</div>
						<div class="tab_line_item">组别编号:{{scope.row.group1_num?scope.row.group1_num:'无'}}</div>
						
						<div class="tab_line_item">上游编号:{{scope.row.out_trade_num?scope.row.out_trade_num:'无'}}</div>

						<div v-if="scope.row.item_name">项目名称:{{scope.row.item_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}</div>
						<div class="tab_line_item">{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.case_link_man_text}}/{{scope.row.case_link_tel_text}}</div>
						<div class="tab_line_item">发车时间:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无详细地址'}}</div>
						<div class="tab_line_item">联系人:{{scope.row.aim_link_man_text}}/{{scope.row.aim_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item" >发: {{scope.row.cargo_weight_start==0?" 0":scope.row.cargo_weight_start+"吨"}}</div>
						<div class="tab_line_item" >到: {{scope.row.cargo_weight_arrived==0?" 0":scope.row.cargo_weight_arrived+"吨"}}</div>
						<div class="tab_line_item" >重量:{{scope.row.cargo_weight==0?"未设置":scope.row.cargo_weight+"吨"}}</div>
						<div class="tab_line_item">体积:{{scope.row.cargo_volume==0?"未设置":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费(元)/其他信息" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">
							<span :class="(scope.row.payed_status_text=='已支付')?'freight_total text_huise':'freight_total' ">{{scope.row.payed_status_text}}</span>/
							<el-button class="btn_left" @click="tord_payed_list(scope.row)" size="mini" type="text">支付记录</el-button>
						</div>
						<div class="tab_line_item">{{`${scope.row.cost_info.freight_total}(总)/${scope.row.cost_info.has_payed_total}(已付)`}}</div>
						<div class="tab_line_item">保障服务费:{{scope.row.cost_info.insure_total}}</div>
						<div class="tab_line_item">货物金额:{{scope.row.cost_info.goods_total}}</div>
						<div class="tab_line_item" v-if="scope.row.cost_info.service_charge_yufu>0">额外服务费:{{scope.row.cost_info.service_charge_yufu}}</div>
						<!-- <div class="tab_line_item">给司机:{{scope.row.cost_info.give_driver_total}}</div>
						<div class="tab_line_item">给收款人:{{scope.row.cost_info.give_truck_owner_total}}</div> -->
						<div class="tab_line_item">{{`${scope.row.settlement_type_text}/${scope.row.is_need_return_text}`}}</div>
						<div class="tab_line_item" :class="(scope.row.is_yufu=='1')?'red':''">是否垫资:{{scope.row.is_yufu==1?'是':'否'}}</div>
						<div class="tab_line_item" :class="(scope.row.is_admin_pay_return=='1')?'red':''">是否回款:{{scope.row.is_admin_pay_return==1?'是':'否'}}</div>
						<div class="tab_line_item" v-if="scope.row.is_yufu==1">
							<el-button @click="yufu_info_open(scope.row)" size="mini" type="text">垫资详情</el-button>
						</div>
						<!-- <div class="tab_line_item" v-if="scope.row.yufu_apply_time>0">预付申请时间:{{scope.row.yufu_apply_time_text}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="支付记录(最多4条)" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item" v-for="(payed_item,index) in scope.row.payed_list" :key="index">{{`${payed_item.much}/${payed_item.pay_status}/${payed_item.pay_mark}`}}
							<div v-if="scope.row.payed_list.length-1 ==index || [1,2,3].indexOf(payed_item.pay_status) >-1" >
								<div>{{payed_item.settled_time_of_truck_owner_text}}</div>
						        <div>{{payed_item.settled_time_of_driver_text}} </div>
						    </div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="子账户信息" width="150px">
					<template slot-scope="scope">
						<div v-if="Object.keys(scope.row.sub_user_info).length!=0">
							<div class="tab_line_item">子账户编号:{{scope.row.sub_user_info.sub_user_num}}</div>
							<div class="tab_line_item">子账户姓名:{{scope.row.sub_user_info.name}}</div>
							<div class="tab_line_item">子账户电话{{scope.row.sub_user_info.tel}}</div>
						</div>
						<div  v-else>
							<div class="tab_line_item">暂无子账户信息</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人/司机/货车信息" width="180px">
					<template slot-scope="scope">
	                    <div class="tab_line_item">收款人:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item" v-if="user_info.tel=='15184738888'">{{scope.row.truck_owner_info.bankcard_info?scope.row.truck_owner_info.bankcard_info.card_num.replace(/^(\d{4})\d+(\d{4})$/,'$1 **** **** $2'):'未绑卡'}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item" v-if="user_info.tel=='15184738888'">{{scope.row.driver_info.bankcard_info?scope.row.driver_info.bankcard_info.card_num.replace(/^(\d{4})\d+(\d{4})$/,'$1 **** **** $2'):'未绑卡'}}</div>
						<div class="tab_line_item">{{scope.row.truck_info.truck_plate_num}}/{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态/时间信息" width="155px">
					<template slot-scope="scope">
						<div class="tab_line_item">状态:{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							锁定:{{scope.row.lock_status_text}}/
							<el-button @click="tord_lock_reason_show(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1" style="color:red">查看原因</el-button>
						</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
						<div class="tab_line_item">建:{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="84">
					<template slot-scope="scope">
						<el-button
							v-if="scope.row.payed_status==1"
							class="btn_left" @click="tord_bl_edit_by_shipper(scope.row,1)" size="mini" type="text">
							更换线路
						</el-button>
						<el-button
							v-if="scope.row.payed_status==1 && scope.row.bl_num"
							class="btn_left" @click="tord_bl_edit_by_shipper(scope.row,2)" size="mini" type="text">
							同步线路
						</el-button>
						<el-button class="btn_left" v-if="scope.row.status==1"  @click="tord_start(scope.row)" size="mini" type="text">发车</el-button>
						<el-button class="btn_left" v-if="scope.row.status==2" @click="tord_arrived(scope.row)" size="mini" type="text">到货</el-button>
						<!-- <el-button class="btn_left" v-if="scope.row.status==3" @click="invoice_apply(scope.row)" size="mini" type="text">开票</el-button> -->
						<el-button class="btn_left" @click="voucher_show(scope.row.truck_tord_num)" size="mini" type="text">凭证</el-button>
						<el-button class="btn_left"  @click="tord_edit_open(scope.row)" v-if="scope.row.payed_status_text!='已支付'" size="mini" type="text">修改</el-button>
						<el-button class="btn_left" @click="tord_delete(scope.row)" v-if="scope.row.is_show ==1 &&scope.row.payed_status_text!='已支付'" size="mini" type="text">删除</el-button>
						<!-- <el-button
							v-if="scope.row.payed_status!=3"
							class="btn_left" @click="tord_pay_open(scope.row,'支付运费','1')" size="mini" type="text">
							支付
						</el-button> -->
						<el-button
							v-if="scope.row.payed_status!=3"
							class="btn_left" @click="tords_pay_open2(scope.row,'支付运费')" size="mini" type="text">
							支付
						</el-button>
						<el-button
							v-if="scope.row.payed_status!=3"
							class="btn_left" @click="tords_pay_open2(scope.row,'请款')" size="mini" type="text">
							请款
						</el-button>
						<!-- <el-button
							v-if="scope.row.payed_status!=3"
							class="btn_left" @click="tord_pay_open(scope.row,'请款','2')" size="mini" type="text">
							请款
						</el-button> -->
						<el-button class="btn_left" @click="truck_trajectory_open(scope.row,1)" size="mini" type="text">轨迹</el-button>

						<el-button class="btn_left" @click="binding_bankcard(scope.row)" size="mini" type="text">绑定银行卡</el-button>
						<!-- <el-button class="btn_left" @click="truck_trajectory_open(scope.row,2)" size="mini" type="text">app轨迹</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 20,30,50,80, 100, 200,500, 1000]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		>
		</el-pagination>

		<!-- 支付记录弹出层 -->
		<!-- <payList
			:pay_list="pay_list.data"
			:calc_mode="pay_list.calc_mode"
			:truck_tord_num="pay_list.truck_tord_num"
			:is_show="pay_list.is_show"
			@pay_list_close="tord_pay_close"
		></payList> -->

		<!-- 支付创建弹出层 -->
		<payCreat
			:tord_info="pay_creat.data"
			:is_show="pay_creat.is_show"
			:title="pay_creat.title"
			:type='pay_creat.type'
			@pay_creat_close="tord_pay_close"
		></payCreat>

		<!-- 批量支付弹出层 -->
		<payBatch
			:is_show="pay_batch.is_show"
			:tord_list="pay_batch.tord_list"
			:num="page.num"
			:title="pay_batch.title"
			:pay_service_status="pay_batch.pay_service_status"
			:type="pay_batch.type"
			@close="pay_batch_close"
		></payBatch>

		<!-- 凭证界面 -->
		<voucher :truck_tord_num="voucher.truck_tord_num" :is_show="voucher.is_show"></voucher>

		<!-- 运单修改界面 -->
		<tordEdit
			@edited="tord_edit_close"
			:tord_info="tord_edit.tord_info"
			:damage_weight="damage_weight"
			:is_show="tord_edit.is_show"
		></tordEdit>

		<!-- 承运商设置 -->
		<setTruckOwnerInfo
			:is_show="truck_owner_info.is_show"
			:data="truck_owner_info.data"
			:check_info="truck_owner_info.check_info"
			@edited="get_page_data"
		></setTruckOwnerInfo>

		<!-- 绑定银行卡 -->
		<bindingBankCard
			:is_show="bank_card.is_show"
			:data="bank_card.data"
		></bindingBankCard>
		<!-- 货车轨迹 -->
		<trucktTrajectory
			v-if="trajectory.is_show"
			:case_county="trajectory.case_county"
			:aim_county="trajectory.aim_county"
			:start_time="trajectory.start_time"
			:end_time="trajectory.end_time"
			:truck_plate_num="trajectory.truck_plate_num"
			:driver_id="trajectory.driver_id"
			:truck_tord_num="trajectory.truck_tord_num"
			@close="truck_trajectory_close"
			:ctr="trajectory.ctr"
		></trucktTrajectory>
        <!-- 批量开票弹窗 -->
		<invoiceApplyDialog  @invoice_success="get_page_data" :is_show="tord_invoice_apply.is_show" :data="tord_invoice_apply.data"></invoiceApplyDialog>
		<!-- 运单锁定原因 -->
		<el-dialog title="运单锁定原因" top="1vh" width="600px" :visible.sync="lock.is_show">
			<div>{{lock.lock_reason}}</div>
		</el-dialog>
		<!-- 运单删除原因 -->
		<el-dialog
		title="运单删除原因"
		:visible.sync="act_delete.is_show"
		width="300px"
		top="30vh"
		@close="tord_delete_close"
		>
		<el-select v-model="act_delete.show_msg" placeholder="请选择删除原因">
			<el-option label="错误接单" value="错误接单"></el-option>
			<el-option label="运输取消" value="运输取消"></el-option>
		</el-select>
		<span slot="footer" class="dialog-footer">
			<el-button @click="act_delete.is_show = false">取 消</el-button>
			<el-button type="primary" @click="tord_act_delete">确 定</el-button>
		</span>
		</el-dialog>

				<!-- 垫资信息 -->
		<el-dialog
				title="垫资信息"
				:visible.sync="yufu_info.is_show"
				width="30%"
				top="30vh"
			>
			<div>
				<div>
					审核状态：{{yufu_info.yufu_status_text}}
				</div>
				<!-- <div v-if="yufu_info.yufu_apply_time_text">
					垫资申请时间：{{yufu_info.yufu_apply_time_text}}
				</div> -->
				<div v-if="yufu_info.yufu_creat_time_text">
					垫资申请时间：{{yufu_info.yufu_creat_time_text}}
				</div>
				<div v-if="yufu_info.yufu_pass_time_text">
					垫资审核通过时间：{{yufu_info.yufu_pass_time_text}}
				</div>
				<div v-if="yufu_info.yufu_unpass_time_text">
					垫资审核打回时间：{{yufu_info.yufu_unpass_time_text}}
				</div>
				<div v-if="yufu_info.yufu_fail_reason">
					垫资审核打回原因：{{yufu_info.yufu_fail_reason}}
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="yufu_info.is_show = false">取 消</el-button>
				<el-button type="primary" @click="yufu_info.is_show = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import payCreat from './pay/pay_creat.vue'
	import payBatch from './pay/pay_batch.vue'
	import {regionData,CodeToText} from 'element-china-area-data'
	import truck_type_handle from '@/my/_truck_type_handle.js'
	import voucher from '../voucher/voucher_before_end.vue'
	import tordEdit from './edit.vue'
	import { Loading } from 'element-ui';
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	import {mapState} from 'vuex'
	import invoiceApplyDialog from '../finance_plan/tords_invoice_apply_dialog.vue'
	import setTruckOwnerInfo from './set_truck_owner_info.vue'
	import bindingBankCard from './binding_bank_card.vue'
	import xlsx from "xlsx";
	export default {
		components:{
			// payList,
			payCreat,
			payBatch,
			voucher,
			tordEdit,
			trucktTrajectory,
			invoiceApplyDialog,
			setTruckOwnerInfo,
			bindingBankCard
		},
		data() {
			return {
				bankcard_ishow_list:['15184738888'],//运单是否显示收款银行卡
				//搜索条件参数
				ser_para:{
					cargo_type:[],//货物类型
					cities:regionData,//省市区数据
				},
				trans_status: "1", //切换
				title:'',//优通项目搜索
				//运单删除原因弹窗
				act_delete:{
					is_show:false,
					show_msg:'',//运单取消原因
					truck_tord_num:'',
				},
				//搜索条件
				form: {
					truck_tord_num:'',//货源编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					status:'',//状态
					payed_status:'',//支付状态
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					mark:'',//备注搜索
					is_set_insure:'',//保障服务费
					file_num:'',//磅单编号搜索
					item_name:'',//项目名称
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time:"",//发车时间
                    end_time:"",//到货时间

					bl_name:"",//线路名称-模糊搜索
					is_set_freight:'',//运费设置

					sub_user_nums:'',

					creat_time_arr:[],//建单时间

					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)

					is_yufu:'',//是否垫资

					is_admin_pay_return:''//是否回款
				},
				creat_time_arr1:'',
				sub_user_loading: false,
				sub_user_list:[],//子账户列表
				date_value:"",
				//快捷时间选择器
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'', //总运费
					total_service:'',//总服务费
					total_much:'',//总金额 运费+服务费
					total_cargo_weight:'',//总吨数
					total_insure:'',//总服务费
					total_give_driver:'',//给司机得总金额
					total_give_truck_owner:'',//给车队长的总金额

				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//待支付列表
				pay_list:{
					data:[],
					calc_mode:'',
					truck_tord_num:'',
					is_show:0,
				},
				// 勾选运费
				tick_freight:0,
				total_cargo_weight:0,//勾选吨数
				freight_total:0,//勾选总计
				service_charge:0,//勾选服务费
				//发起支付
				pay_creat:{
					data:{},
					is_show:0,
					title:'',
					type:'0'
				},

				//批量支付
				pay_batch:{
					tord_list:[],
					is_show:0,
					title:'',
				},

				//运费修改相关
				tord_edit:{
					is_show:0,
					tord_info:{}
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
					driver_id:null,
					ctr:null,
					truck_tord_num:null,
				},

				//选中的运单
				tords_choosed:[],
				//批量开票相关
				tord_invoice_apply:{
					is_show:0,
					data:[],
				},
				//运单锁定原因
				lock:{
					is_show:false,
					lock_reason:'',
				},
				excle_list:{
					p:1,
					data:[]
				},
				settled_time_of_truck_owner_text:'',
				settled_time_of_driver_text:'',

				tab_height:'tab_height1',

				damage_weight:'',//货主货损

				num:0,

				//设置承运商
				truck_owner_info:{
					is_show:0,
					data:null,
					check_info:'',
				},
				//银行卡绑定
				bank_card:{
					is_show:0,
					data:null,
				},
				//垫资相关
				yufu_info:{
					is_show:false,
					yufu_status_text:'',
					yufu_fail_reason:'',
					yufu_creat_time_text:'',
					yufu_pass_time_text:'',
					yufu_unpass_time_text:'',
				}
			}
		},
		computed:{
			...mapState(["user_info"])
		},
		created() {
			//获取可用搜索参数
			this.get_ser_para()

			//读取货源
			this.get_page_data()

		},
		mounted(){
			if(["18039266325","18621627027"].indexOf(this.user_info.tel)>-1){
				this.title = '项目搜索'
			}else{
				this.title = '线路搜索'
			}
			if(['13953419303'].indexOf(this.user_info.tel)>-1){
				this.tab_height='tab_height'
			}
		},
		methods: {
			//打卡垫资信息
			yufu_info_open(item){
				this.yufu_info.is_show=true
				//预付状态
				switch(item.yufu_status){
					case '1':this.yufu_info.yufu_status_text="待审核";break;
					case '2':this.yufu_info.yufu_status_text="审核通过";break;
					case '3':this.yufu_info.yufu_status_text="审核打回";break;
				}
				//垫资申请时间
				if(item.yufu_creat_time>0){
					this.yufu_info.yufu_creat_time_text =  this.$my.other.totime(item.yufu_creat_time);
				}
				//垫资审核通过时间
				if(item.yufu_pass_time>0){
					this.yufu_info.yufu_pass_time_text =  this.$my.other.totime(item.yufu_pass_time);
				}
				//垫资审核打回时间
				if(item.yufu_unpass_time>0){
					this.yufu_info.yufu_unpass_time_text =  this.$my.other.totime(item. yufu_unpass_time);
				}
				//垫资审核打回原因
				if(item.yufu_fail_reason){
					this.yufu_info.yufu_fail_reason =  item.yufu_fail_reason
				}
				console.log(this.yufu_info)
			},
			//银行卡绑定
			binding_bankcard(data){
				
				this.bank_card.is_show ++
				this.bank_card.data = data
			},
			//去服务器读取线路
			bl_focus(){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_shipper',
						bl_name:this.form.bl_name,
					},
					callback:(data)=>{
						var bl_list = []
						//遍历
						for(var item of data.list){

							//结算方式
							var settlement_type_text=""
							switch(item.is_need_return){
								case '1':settlement_type_text="按吨数结算";break;
								case '2':settlement_type_text="按方数结算";break;
								case '3':settlement_type_text="按趟结算";break;
							}

							//是否是双边
							var is_need_return_text=""
							switch(item.is_need_return){
								case '1':is_need_return_text="双边";break;
								case '2':is_need_return_text="单边";break;
							}

							//装货地其他数据
							item.case_other_obj=JSON.parse(item.case_other);

							//卸货地其他数据
							item.aim_other_obj=JSON.parse(item.aim_other);

							//汇集路线数据
							item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'
							bl_list.push({
								value:item.bl_name,
								address:item.bl_name
							})
						}
						this.restaurants = bl_list
					}
				})
			},
			querySearch(queryString, cb) {
				var restaurants = this.restaurants;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			//承运商设置
			set_truck_owner(){
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				
				this.truck_owner_info.data = this.tords_choosed
				this.truck_owner_info.check_info = '当前勾选总条数'+this.tords_choosed.length + '' +'总运费'+this.tick_freight+'元(不含服务费)'
				this.truck_owner_info.is_show ++
			},
			//线路更换 //同步
			tord_bl_edit_by_shipper(item,type){
				//线路同步
				if(type ==1){
					 this.$prompt('请输入新的线路编号', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						}).then(({ value }) => {
							this.$my.net.req({
								data:{
									mod:'truck_tord_real_time',
									ctr:'tord_bl_edit_by_shipper',
									truck_tord_num:item.truck_tord_num,
									bl_num:value
								},callback:(data)=>{
									this.$my.other.msg({
										type:"success",
										str:'操作成功'
									});

									//刷新
									this.get_page_data()
								}
							})
						}).catch(() => {   
					});
				}else{
					this.$my.net.req({
						data:{
							mod:'truck_tord_real_time',
							ctr:'tord_bl_edit_by_shipper',
							truck_tord_num:item.truck_tord_num,
							bl_num:item.bl_num
						},callback:(data)=>{
							this.$my.other.msg({
								type:"success",
								str:'操作成功'
							});

							//刷新
							this.get_page_data()
						}
					})
				}
			},
			//凭证下载
			 voucher_download(url,name) {
					let image = new Image();
					image.setAttribute('crossOrigin', 'anonymous');
					image.src = url;
					image.onload = () => {
						let canvas = document.createElement('canvas');
						canvas.width = image.width;
						canvas.height = image.height;
						let ctx = canvas.getContext('2d');
						ctx.drawImage(image, 0, 0, image.width, image.height);
						canvas.toBlob((blob) => {
						let url = URL.createObjectURL(blob);
						this.download(url,name);
						// 用完释放URL对象
						URL.revokeObjectURL(url);
						});
					};
				},
			download(href,name) {
					let eleLink = document.createElement('a');
					eleLink.download = name;
					eleLink.href = href;
					eleLink.click();
					eleLink.remove();
			},
			//关闭下载等待特效
			close_loading(){
				let loadingInstance = Loading.service(this.options);
				this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
				loadingInstance.close();
				});
			},
			//回单批量下载
			batch_download_voucher(){
				Loading.service(this.options);
				var choosed = this.tords_choosed
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:"info",
						str:'当前未勾选数据'
					});
					return
				}
				//关闭等待特效
				if(this.num==this.tords_choosed.length){
					this.close_loading()
					this.num = 0
					return
				}
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_voucher_list',
						truck_tord_num:choosed[this.num].truck_tord_num
					},callback:(data)=>{
						if(data.msg.list.length ==0){
							this.num ++ 
							this.batch_download_voucher()
							return
						}
						if(data.code ==0){
							let nums = 0
							var name = ''
							data.msg.list.forEach(item=>{
									switch(item.upl_user_type){
									case '1':var upl_type_text="货主上传";break;
									case '2':var upl_type_text="司机上传";break;
									case '3':var upl_type_text="后台上传";break;
									case '4':var upl_type_text="货主子账号上传";break;
									case '5':var upl_type_text="网点上传";break;
								}
								switch(item.type){
									case '1':var type_text="磅单";break;
									case '2':var type_text="回单";break;
								}
								name = choosed[this.num].truck_tord_num + '-'+upl_type_text + '-' +type_text
								var url =this.$my.qiniu.make_src('trucktordrealtimevoucher', item.img_key)
								this.voucher_download(url,name)
								nums ++
								if(nums == data.msg.list.length){
									this.num ++ 
									this.batch_download_voucher()
								}
								
							})
						}
					}
				})
			},
			top_change(value){
				// switch(value){
				// 	case '':this.form.status=''
				// 	        this.form.payed_status='';
				// 	        break;
				// 	case '1':this.form.status='1';break;
				// 	case '2':this.form.status='2';break;
				// 	case '3':this.form.status='3';break;
				// 	// case '4':this.form.payed_status='1';break;
				// 	// case '5':this.form.payed_status='2';break;
				// 	// case '6':this.form.payed_status='3';break;
				// }
				this.get_page_data()
			},
			tord_delete_close(){
				this.act_delete.show_msg=''
				this.act_delete.truck_tord_num = ''
			},
			//运单删除
			tord_act_delete(){
					this.$my.other.confirm({
					content:"是否确认提交删除?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_hide',
								truck_tord_nums:[this.act_delete.truck_tord_num],
								type:1,
								show_msg:this.act_delete.show_msg
							},callback:(res)=>{
								this.$my.other.msg({
									str:'提交成功',
									type:'success'
								});
								this.get_page_data(),
								this.act_delete.show_msg=''
								this.act_delete.is_show = false
								this.act_delete.truck_tord_num = ''
							}
						})
					}
				});
			},
			tord_delete(item){
				this.act_delete.is_show = true
				this.act_delete.truck_tord_num = item.truck_tord_num
			},
			//生成csv
			make_csv(data){
					const _data = [
					// 代表 导出 列标题
					[
					"系统id",
					"运单编号",
					"上游编号",
					"线路编号",
					"线路名称",
					"子账户姓名",
					"子账户手机号",
					"建单时间",
					"货物体积(方)",
					"货物重量(吨)",
					"发货重量(吨)",
					"到货重量(吨)",
					"货物名称",
					"货主电话",
					"收款人姓名",
					"收款人电话",
					"司机姓名",
					"司机电话",
					"车牌号",
					"车辆类型",
					"发货地省份",
					"发货地城市",
					"发货地县区",
					"发货地详细地址",
					"发车时间",
					"发货地联系人",
					"发货地联系电话",
					"到货地省份",
					"到货地城市",
					"到货地县区",
					"到货地详细地址",
					"到货时间",
					"到货地联系人",
					"到货地联系电话",
					"运输距离",
					"运费",
					"结算给车队长",
					"结算给司机",
					"已支付",
					"车队长已支付",
					"司机已支付",
					"服务费",
					"保险费",
					"当前司机收款人卡号",
					"当前司机收款人身份证号",
					"当前司机收款人姓名",
					"当前司机收款人手机号",
					"当前收款人卡号",
					"当前收款人身份证号",
					"当前收款人姓名",
					"当前收款人手机号",
					],
				];
				data.forEach((item) => {
					_data.push(Object.values(item));
				});
				const ws = xlsx.utils.aoa_to_sheet(_data);
				const wb = xlsx.utils.book_new();
				xlsx.utils.book_append_sheet(wb, ws, "SheetJS");
				xlsx.writeFile(wb, "运单列表.xlsx");
				this.excle_list.data=[]
				// var shunxu=[
				// 	{key:"id",cname:"系统id"},
				// 	{key:"truck_tord_num",cname:"运单编号"},
				// 	{key:"out_trade_num",cname:"上游编号"},
				// 	{key:"sub_user_info_nmae",cname:"子账户姓名"},
				// 	{key:"sub_user_info_tel",cname:"子账户手机号"},
				// 	{key:"creat_time_text",cname:"建单时间"},
				// 	{key:"cargo_volume",cname:"货物体积(方)"},
				// 	{key:"cargo_weight",cname:"货物重量(吨)"},
				// 	{key:"cargo_weight_start",cname:"发货重量(吨)"},
				// 	{key:"cargo_weight_arrived",cname:"到货重量(吨)"},
				// 	{key:"cargo_name",cname:"货物名称"},
				// 	{key:"shipper_info_tel",cname:"货主电话"},
				// 	{key:"truck_owner_info_name",cname:"收款人姓名"},
				// 	{key:"truck_owner_info_tel",cname:"收款人电话"},
				// 	{key:"driver_info_name",cname:"司机姓名"},
				// 	{key:"driver_info_tel",cname:"司机电话"},
				// 	{key:"truck_plate_num",cname:"车牌号"},
				// 	{key:"case_prov",cname:"发货地省份"},
				// 	{key:"case_city",cname:"发货地城市"},
				// 	{key:"case_county",cname:"发货地县区"},
				// 	{key:"case_addr_info",cname:"发货地详细地址"},
				// 	{key:"start_time_text",cname:"发车时间"},
				// 	{key:"case_link_man_text",cname:"发货地联系人"},
				// 	{key:"case_link_tel_text",cname:"发货地联系电话"},
				// 	{key:"aim_prov",cname:"到货地省份"},
				// 	{key:"aim_city",cname:"到货地城市"},
				// 	{key:"aim_county",cname:"到货地县区"},
				// 	{key:"aim_addr_info",cname:"到货地详细地址"},
				// 	{key:"arrived_time_text",cname:"到货时间"},
				// 	{key:"aim_link_man_text",cname:"到货地联系人"},
				// 	{key:"aim_link_tel_text",cname:"到货地联系电话"},
				// 	{key:"distance",cname:"运输距离"},
				// 	{key:"excle_freight_total",cname:"运费"},
				// 	{key:"give_truck_owner_total",cname:"结算给车队长"},
				// 	{key:"give_driver_total",cname:"结算给司机"},
				// 	{key:"has_payed_total",cname:"已支付"},
				// 	{key:"truck_owner_settled_total",cname:"车队长已支付"},
				// 	{key:"driver_settled_total",cname:"司机已支付"},
				// 	{key:"service_charge2",cname:"服务费"},

				// ],str="";
				// for(var a in shunxu){
				// 	str+=shunxu[a].cname+",";
				// }
				// str+="\n";
				// for(var i=0;i<data.length;i++){
				// 	for(var a in shunxu){
				// 		str+=`${data[i][shunxu[a].key]}\t,`;
				// 	}
				// 	str+='\r\n';
				// }

				// str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				// //生成下载链接
				// let link = document.createElement('a');
				// link.href = str;

				// //对下载的文件命名
				// link.download = "运单列表.xlsx";

				// //下载
				// document.body.appendChild(link);
				// link.click();
				// document.body.removeChild(link);
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => {
					loadingInstance.close();
				});
			},
			tord_excle_list(){
				var num = 100
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_by_shipper',
						...this.form,
						p:this.excle_list.p,
						need_num: 1,
						num,
					},
					callback:(data)=>{
						if(data.msg.list.length !=0){
							//加载完毕
							this.list.loading=false


							//预处理
							for(let item of data.msg.list){
								if(item.sub_user_info.length!=0){
									item.sub_user_info_nmae = item.sub_user_info.name
									item.sub_user_info_tel = item.sub_user_info.tel
								}else{
									item.sub_user_info_nmae = ''
									item.sub_user_info_tel = ''
								}
								item.shipper_info_tel = this.user_info.tel
								item.truck_owner_info_name = item.truck_owner_info.name
								item.truck_owner_info_tel = item.truck_owner_info.tel
								item.driver_info_name = item.driver_info.name
								item.driver_info_tel = item.driver_info.tel
								item.excle_freight_total = item.cost_info.freight_total
								item.give_truck_owner_total = item.cost_info.give_truck_owner_total
								item.truck_owner_settled_total = item.cost_info.truck_owner_settled_total
								item.driver_settled_total = item.cost_info.driver_settled_total
								// item.give_truck_owner_tel = item.truck_owner_info.tel
								item.service_charge2 = item.cost_info.service_charge
								item.give_driver_total = item.cost_info.give_driver_total
								item.has_payed_total = item.cost_info.has_payed_total
							    //成交时间
								item.creat_time_text=this.$my.other.totime(item.creat_time);

								//发车时间
								if(item.start_time>0){//已发车
									item.start_time_text=this.$my.other.totime(item.start_time);
								}else item.start_time_text="未发车";

								//到货时间
								if(item.arrived_time>0){//已到货
									item.arrived_time_text=this.$my.other.totime(item.arrived_time);
								}else item.arrived_time_text="未到货";

								//装货联系人
								if(item.case_link_man){
									item.case_link_man_text=item.case_link_man;
								}else item.case_link_man_text="无";

								//装货联系电话
								if(item.case_link_tel){
									item.case_link_tel_text=item.case_link_tel;
								}else item.case_link_tel_text="无";

								//卸货联系人
								if(item.aim_link_man){
									item.aim_link_man_text=item.aim_link_man;
								}else item.aim_link_man_text="无";

								//卸货联系电话
								if(item.aim_link_tel){
									item.aim_link_tel_text=item.aim_link_tel;
								}else item.aim_link_tel_text="无";

								/* 装货地其他数据(json) */
								item.case_other_obj=JSON.parse(item.case_other);
								item.case_addr_info = item.case_other_obj.addr_info?item.case_other_obj.addr_info:'无详细地址'
								//装货时间
								if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

								}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

								}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

									item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

								}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

									item.case_other_obj.case_date="未设置";
								}

								//发车时间
								if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

								}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

								}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

									item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

								}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有

									item.case_other_obj.start_time="未设置";
								}

								//发车时间是否强制校验
								switch(item.case_other_obj.start_time_is_check){
									case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
									case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
								}
								if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
									item.case_other_obj.start_time_is_check_text=""
								}

								/* 卸货地其他数据(json) */
								item.aim_other_obj=JSON.parse(item.aim_other);
								item.aim_addr_info = item.aim_other_obj.addr_info?item.aim_other_obj.addr_info:'无详细地址'
								//卸货时间
								if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

								}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

								}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

									item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

								}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有

									item.aim_other_obj.aim_date="未设置";
								}

								//运单状态
								switch(item.status){
									case '1':item.status_text="待发车";break;
									case '2':item.status_text="运输中";break;
									case '3':item.status_text="已到货";break;
								}

								//锁定状态
								switch(item.lock_status){
									case '1':item.lock_status_text="已锁定";break;
									case '2':item.lock_status_text="未锁定";break;
								}

								//结算方式
								switch(item.settlement_type){
									case '1':item.settlement_type_text="按吨数";break;
									case '2':item.settlement_type_text="按方数";break;
									case '3':item.settlement_type_text="按趟";break;
								}

								//是否回程
								switch(item.is_need_return){
									case '1':item.is_need_return_text="需回程";break;
									case '2':item.is_need_return_text="不需回程";break;
								}

								//匹配方式
								switch(item.match_way){
									case '1':item.match_way_text="收款人抢单";break;
									case '2':item.match_way_text="指定后收款人接单";break;
									case '3':item.match_way_text="收款人竞价中标";break;
									case '4':item.match_way_text="收款人扫码接单";break;
									case '5':item.match_way_text="网点线下匹配";break;
									case '6':item.match_way_text="合作方匹配";break;
									case '7':item.match_way_text="后台匹配";break;
								}

								//运费总额
								if(item.cost_info.freight_total){
									item.cost_info.freight_total_text=item.cost_info.freight_total+"元";
								}else item.cost_info.freight_total_text="未设置";

								//已付总额
								item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+"元"

								//运费支付状态
								switch(item.payed_status){
									case '1':item.payed_status_text="未支付";break;
									case '2':item.payed_status_text="部分支付";break;
									case '3':item.payed_status_text="已支付";break;
								}
								//货车类型
								item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+'/'+item.truck_info.truck_type_name2
								//置入导出数据
								item.payed_list=[];
								this.excle_list.data.push({
									id: item.id,
									truck_tord_num: item.truck_tord_num,
									out_trade_num: item.out_trade_num,
									bl_num: item.bl_num,
									bl_name: item.bl_name,
									sub_user_info_nmae: item.sub_user_info_nmae,
									sub_user_info_tel: item.sub_user_info_tel,
									creat_time_text: item.creat_time_text,
									cargo_volume: item.cargo_volume,
									cargo_weight: item.cargo_weight,
									cargo_weight_start: item.cargo_weight_start,
									cargo_weight_arrived: item.cargo_weight_arrived,
									cargo_name: item.cargo_name,
									shipper_info_tel: item.shipper_info_tel,
									truck_owner_info_name: item.truck_owner_info_name,
									truck_owner_info_tel: item.truck_owner_info_tel,
									driver_info_name: item.driver_info_name,
									driver_info_tel: item.driver_info_tel,
									truck_plate_num: item.truck_plate_num,
									truck_type_name_text:item.truck_info.truck_type_name_text,
									case_prov:item.case_prov,
									case_city: item.case_city,
									case_county: item.case_county,
									case_addr_info: item.case_addr_info,
									start_time_text: item.start_time_text,
									case_link_man_text: item.case_link_man_text,
									case_link_tel_text: item.case_link_tel_text,
									aim_prov: item.aim_prov,
									aim_city: item.aim_city,
									aim_county: item.aim_county,
									aim_addr_info: item.aim_addr_info,
									arrived_time_text: item.arrived_time_text,
									aim_link_man_text: item.aim_link_man_text,
									aim_link_tel_text: item.aim_link_tel_text,
									distance: item.distance,
									excle_freight_total: item.excle_freight_total,
									give_truck_owner_total: item.give_truck_owner_total,
									give_driver_total: item.give_driver_total,
									has_payed_total: item.has_payed_total,
									truck_owner_settled_total: item.truck_owner_settled_total,
									driver_settled_total: item.driver_settled_total,
									service_charge2: item.service_charge2,
									insure_total: item.cost_info.insure_total,
									driver_card_num:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_num:'无',
									driver_card_user_id_card_num:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_id_card_num:'无',
									driver_card_user_name:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_name:'无',
									driver_card_user_tel:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_tel:'无',

									truck_owner_info_card_num:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_num:'无',
									truck_owner_info_card_user_id_card_num:item.truck_owner_info.card_user_id_card_num?item.truck_owner_info.bankcard_info.card_user_id_card_num:'无',
									truck_owner_info_card_user_name:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_user_name:'无',
									truck_owner_info_card_user_tel:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_user_tel:'无',
								})
							}
							this.excle_list.p++
							this.tord_excle_list()
						}else{
							
							this.make_csv(this.excle_list.data)
							this.excle_list.p=1
						}
					}
				})
			},
			//全部导出
			tord_whole_excle(){
				if(this.tords_choosed.length == 0){
					//未勾选数据
					this.$my.other.confirm({
						content:"当前未勾选数据,是否全部导出",
						confirm:()=>{
							this.tord_excle_list()
							Loading.service(this.options);
						}
					});
				}else{
					this.tords_choosed.forEach(item=>{
						item.service_charge2 = item.cost_info.service_charge
						item.bl_num = item.bl_num
						item.bl_name = item.bl_name
						item.truck_owner_info_name = item.truck_owner_info.name
						item.truck_owner_info_tel = item.truck_owner_info.tel
						item.driver_info_name = item.driver_info.name
						item.driver_info_tel = item.driver_info.tel
						item.excle_freight_total = item.cost_info.freight_total
						item.aim_addr_info = item.aim_other_obj.addr_info?item.aim_other_obj.addr_info:'无详细地址'
						item.case_addr_info = item.case_other_obj.addr_info?item.case_other_obj.addr_info:'无详细地址'
						item.give_truck_owner_total = item.cost_info.give_truck_owner_total
						item.give_driver_total = item.cost_info.give_driver_total
						item.has_payed_total = item.cost_info.has_payed_total
						item.truck_owner_settled_total = item.cost_info.truck_owner_settled_total
						item.driver_settled_total = item.cost_info.driver_settled_total
						if(item.sub_user_info.length!=0){
							item.sub_user_info_nmae = item.sub_user_info.name
							item.sub_user_info_tel = item.sub_user_info.tel
						}else{
						    item.sub_user_info_nmae = ''
							item.sub_user_info_tel = ''
						}
						item.shipper_info_tel = this.user_info.tel
						this.excle_list.data.push({
							id: item.id,
							truck_tord_num: item.truck_tord_num,
							out_trade_num: item.out_trade_num,
							bl_num: item.bl_num,
							bl_name: item.bl_name,
							sub_user_info_nmae: item.sub_user_info_nmae,
							sub_user_info_tel: item.sub_user_info_tel,
							creat_time_text: item.creat_time_text,
							cargo_volume: item.cargo_volume,
							cargo_weight: item.cargo_weight,
							cargo_weight_start: item.cargo_weight_start,
							cargo_weight_arrived: item.cargo_weight_arrived,
							cargo_name: item.cargo_name,
							shipper_info_tel: item.shipper_info_tel,
							truck_owner_info_name: item.truck_owner_info_name,
							truck_owner_info_tel: item.truck_owner_info_tel,
							driver_info_name: item.driver_info_name,
							driver_info_tel: item.driver_info_tel,
							truck_plate_num: item.truck_plate_num,
							truck_type_name_text:item.truck_info.truck_type_name_text,
							case_prov:item.case_prov,
							case_city: item.case_city,
							case_county: item.case_county,
							case_addr_info: item.case_addr_info,
							start_time_text: item.start_time_text,
							case_link_man_text: item.case_link_man_text,
							case_link_tel_text: item.case_link_tel_text,
							aim_prov: item.aim_prov,
							aim_city: item.aim_city,
							aim_county: item.aim_county,
							aim_addr_info: item.aim_addr_info,
							arrived_time_text: item.arrived_time_text,
							aim_link_man_text: item.aim_link_man_text,
							aim_link_tel_text: item.aim_link_tel_text,
							distance: item.distance,
							excle_freight_total: item.excle_freight_total,
							give_truck_owner_total: item.give_truck_owner_total,
							give_driver_total: item.give_driver_total,
							has_payed_total: item.has_payed_total,
							truck_owner_settled_total: item.truck_owner_settled_total,
							driver_settled_total: item.driver_settled_total,
							service_charge2: item.service_charge2,
							insure_total: item.cost_info.insure_total,

							driver_card_num:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_num:'无',
							driver_card_user_id_card_num:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_id_card_num:'无',
							driver_card_user_name:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_name:'无',
							driver_card_user_tel:item.driver_info.bankcard_info?item.driver_info.bankcard_info.card_user_tel:'无',

							truck_owner_info_card_num:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_num:'无',
							truck_owner_info_card_user_id_card_num:item.truck_owner_info.card_user_id_card_num?item.truck_owner_info.bankcard_info.card_user_id_card_num:'无',
							truck_owner_info_card_user_name:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_user_name:'无',
							truck_owner_info_card_user_tel:item.truck_owner_info.bankcard_info?item.truck_owner_info.bankcard_info.card_user_tel:'无',
						})
					})
					this.make_csv(this.excle_list.data)
					// Loading.service(this.options);
				}
			},
			//运费相关操作
			tord_edit_open(item){//打开修改界面
				this.tord_edit.is_show++;
				this.damage_weight = item.damage_weight
				this.tord_edit.tord_info={...item}
			},
			tord_edit_close(){//关闭修改界面
				this.tord_edit.is_show=0
				this.tord_edit.tord_info={}

				//刷新
				this.get_page_data()
			},

			//选择待支付
			tords_list_choose(event){
				var tick_freight = 0
				var total_cargo_weight = 0
				var service_charge = 0
				var freight_total = 0 
				event.forEach(item=>{
					tick_freight += Number(item.cost_info.freight_total) * 100
					total_cargo_weight += Number(item.cargo_weight) * 100
					service_charge += Number(item.cost_info.service_charge) * 100
				})
				this.tick_freight =tick_freight /100
				this.total_cargo_weight = total_cargo_weight /100
				this.service_charge = service_charge /100
				freight_total = this.tick_freight+this.service_charge
				this.freight_total = freight_total.toFixed(2)
				//置入
				this.tords_choosed=event
			},

			//轨迹
			truck_trajectory_open(item,num){
				if(num ==1){
				  //中交轨迹
				  this.trajectory.ctr = 'truck_trajectory_logink'
				}else if(num ==2){
					//app轨迹
				  this.trajectory.ctr = 'truck_trajectory_app'
				}
				this.trajectory.truck_tord_num = item.truck_tord_num
				this.trajectory.case_county=item.case_county;
				this.trajectory.aim_county=item.aim_county;
				this.trajectory.start_time=parseInt(item.start_time);
				this.trajectory.end_time=item.status==2?parseInt(new Date().getTime()/1000):parseInt(item.arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.driver_id = item.driver_info.id
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},

			//查看运单锁定原因
			tord_lock_reason_show(item){
				this.lock.is_show=true;
				this.lock.lock_reason=item.lock_reason;
			},

			//批量运费核对
			tords_freight_check(){
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集未支付
				let tord_arr=[];
				for(var item of this.tords_choosed){
					if(item.payed_status==1&&item.settlement_type==1){
						tord_arr.push(item.truck_tord_num);
					}
				}

				if(tord_arr.length==0){
					this.$my.other.msg({
						str:'缺少符合条件的运单(已支付，切按吨结算)',
						type:'failed'
					});
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量重新核对并修改运费',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_tord_cost_admin',
								truck_tord_nums:tord_arr,
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});

			},
			//申请开票
			tords_invoice_apply(){//批量
			    
				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要申请开票的运单
				let payed_need_apply=[];
				for(var item of this.tords_choosed){
					if(item.invoiced_status!=3){

						payed_need_apply.push({
							truck_tord_num:item.truck_tord_num,
							payed_total_of_all:item.cost_info.payed_total_of_all,
							cargo_weight:item.cargo_weight,
							freight_total:item.cost_info.freight_total
						});
					}
				}
				//已选择运单中没有可以申请开票的
				if(payed_need_apply.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有可以申请开票的'
					})
					return;
				}
				//提示
				// this.$my.other.confirm({
				// 	content:"您的操作是'批量申请开票',请确认",
				// 	confirm:()=>{
			
				// 		//提交
				// 		this.$my.net.req({
				// 			data:{
				// 				mod:'truck_tord_real_time',
				// 				ctr:'tord_invoice_apply_by_shipper',
				// 				apply_list:payed_need_apply,
				// 			},
				// 			callback:(data)=>{
			
				// 				//提醒
				// 				this.$my.other.msg({
				// 					str:'操作成功',
				// 					type:'success'
				// 				});
								
				// 				//关闭弹窗
                //                 // this.is_show_in_page=false
				// 				this.get_page_data()
				// 			},
				// 		});
				// 	}
				// });
				this.tord_invoice_apply.data = payed_need_apply
				this.tord_invoice_apply.is_show++

			},
			// //申请开票
			// tords_invoice_apply(){//批量

			// 	//没有选择运单
			// 	if(this.tords_choosed.length==0){
			// 		this.$my.other.msg({
			// 			type:'warning',
			// 			str:'请选择运单'
			// 		})
			// 		return;
			// 	}

			// 	//汇集需要申请开票的运单
			// 	let payed_need_apply=[];
			// 	for(var item of this.tords_choosed){
			// 		if(item.invoiced_status!=3){
			// 			payed_need_apply.push({
			// 				truck_tord_num:item.truck_tord_num,
			// 			});
			// 		}
			// 	}

			// 	//已选择运单中没有可以申请开票的
			// 	if(payed_need_apply.length==0){
			// 		this.$my.other.msg({
			// 			type:'warning',
			// 			str:'已选择运单中没有可以申请开票的'
			// 		})
			// 		return;
			// 	}

			// 	//提示
			// 	this.$my.other.confirm({
			// 		content:"您的操作是'批量申请开票',请确认",
			// 		confirm:()=>{

			// 			//提交
			// 			this.$my.net.req({
			// 				data:{
			// 					mod:'truck_tord_real_time',
			// 					ctr:'tord_invoice_apply_by_shipper',
			// 					apply_list:payed_need_apply,
			// 				},
			// 				callback:(data)=>{

			// 					//提醒
			// 					this.$my.other.msg({
			// 						str:'操作成功',
			// 						type:'success'
			// 					});

			// 					//刷新本页
			// 					this.get_page_data()
			// 				},
			// 			});
			// 		}
			// 	});
			// },
			invoice_apply(item){//单个申请开票

				//暂时给个弹窗 以后有空改成开票预览
				this.$my.other.confirm({
					content:"点击'确定'申请开票",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_invoice_apply_by_shipper',
								apply_list:[
									{
										truck_tord_num:item.truck_tord_num,
									}
								]
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//凭证相关操作
			voucher_show(truck_tord_num){//显示凭证
				this.voucher.is_show++;
				this.voucher.truck_tord_num=truck_tord_num
			},

			//支付记录
			tord_payed_list(item){

				//跳转
				this.$router.push({
					path:'/pages/tord_real_time/tord_pay_list',
					query:{
						truck_tord_num:item.truck_tord_num
					}
				});
			},
			//单个支付 调用批量支付样式
			tords_pay_open2(data,title){
				//没有选择运单
				// if(this.tords_choosed.length==0){
				// 	this.$my.other.msg({
				// 		type:'warning',
				// 		str:'请选择运单'
				// 	})
				// 	return;
				// }

				//汇集需要到货的运单
				let tords_need_pay=[];
				if(data.cost_info.freight_total==0){
					this.$my.other.msg({
						type:'warning',
						str:`运单'${data.truck_tord_num}'没有核定运费,请查看`
					})
					return;
				}

				if(data.payed_status==3){
					this.$my.other.msg({
						type:'warning',
						str:`运单'${data.truck_tord_num}'已支付完毕,请查看`
					})
					return;
				}

				tords_need_pay.push(data);

				//已选择运单中没有需要到货的
				if(tords_need_pay.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要支付的'
					})
					return;
				}

				//置入数据
				this.pay_batch.tord_list=tords_need_pay
				this.pay_batch.pay_service_status = this.list.pay_service_status
				this.pay_batch.title = title
				//打开支付界面
				this.pay_batch.is_show++;
			},
			//批量支付
			tords_pay_open(title){

				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要到货的运单
				let tords_need_pay=[];
				for(var item of this.tords_choosed){

					if(item.cost_info.freight_total==0){
						this.$my.other.msg({
							type:'warning',
							str:`运单'${item.truck_tord_num}'没有核定运费,请查看`
						})
						return;
					}

					if(item.payed_status==3){
						this.$my.other.msg({
							type:'warning',
							str:`运单'${item.truck_tord_num}'已支付完毕,请查看`
						})
						return;
					}

					tords_need_pay.push(item);
				}

				//已选择运单中没有需要到货的
				if(tords_need_pay.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要支付的'
					})
					return;
				}

				//置入数据
				this.pay_batch.tord_list=tords_need_pay
				this.pay_batch.pay_service_status = this.list.pay_service_status
				this.pay_batch.title = title
				//打开支付界面
				this.pay_batch.is_show++;
			},
			pay_batch_close(){

				//关闭支付界面
				this.pay_batch.is_show=0;

				//置入数据
				this.pay_batch.truck_tord_nums=[]

				//刷新页面
				this.get_page_data()
			},

			//单笔支付
			tord_pay_open(item,title,type){
				//置入数据
				this.pay_creat.data=item
				this.pay_creat.title = title
				this.pay_creat.type = type
				//打开界面
				this.pay_creat.is_show++

				// //读取支付记录
				// this.$my.net.req({
				// 	data:{
				// 		mod:'truck_tord_real_time',
				// 		ctr:'tord_payed_list_by_shipper',
				// 		truck_tord_num:item.truck_tord_num,
				// 		pay_status:1,//支付状态(1:未支付,2:部分支付,3:支付完毕)
				// 		is_get_truck_owner_info:false,
				// 		is_get_driver_info:false,
				// 		is_get_tord_info:false,
				// 		num:100000,
				// 	},
				// 	callback:(data)=>{

				// 		//有数据, 打开待支付列表
				// 		if(data.list.length>0){

				// 			//置入数据
				// 			this.pay_list.data=data.list
				// 			this.pay_list.calc_mode=item.cost_info.calc_mode
				// 			this.pay_list.truck_tord_num=item.cost_info.truck_tord_num

				// 			//打开界面
				// 			this.pay_list.is_show++

				// 		//无数据, 打开支付创建界面
				// 		}else{

				// 			//置入数据
				// 			this.pay_creat.data=item

				// 			//打开界面
				// 			this.pay_creat.is_show++
				// 		}
				// 	}
				// })
			},
			tord_pay_close(){

				//清空支付记录信息
				this.pay_list.data=[],

				//关闭支付记录界面
				this.pay_list.is_show=0;

				//清空支付创建数据
				this.pay_creat.data={},

				//关闭支付创建界面
				this.pay_creat.is_show=0;

				//刷新页面
				this.get_page_data()
			},

			//批量确认到货
			tords_arrived(){

				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要到货的运单
				let tords_need_arrived=[];
				for(var item of this.tords_choosed){
					if(item.status==2){
						tords_need_arrived.push(item.truck_tord_num);
					}
				}

				//已选择运单中没有需要到货的
				if(tords_need_arrived.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要到货的'
					})
					return;
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量到货',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_arrived_by_shipper',
								truck_tord_nums:tords_need_arrived,
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//确认到货
			tord_arrived(item){

				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认到货',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_arrived_by_shipper',
								truck_tord_nums:[item.truck_tord_num],
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//批量确认发车
			tords_start(){

				//没有选择运单
				if(this.tords_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择运单'
					})
					return;
				}

				//汇集需要发车的运单
				let tords_need_start=[];
				for(var item of this.tords_choosed){
					if(item.status==1){
						tords_need_start.push(item.truck_tord_num);
					}
				}

				//已选择运单中没有需要发车的
				if(tords_need_start.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择运单中没有需要发车的'
					})
					return;
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是'批量发车',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_start_by_shipper',
								truck_tord_nums:tords_need_start,
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//确认发车
			tord_start(item){

				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认发车',请确认",
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tords_start_by_shipper',
								truck_tord_nums:[item.truck_tord_num],
							},
							callback:(data)=>{

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					truck_tord_num:'',//货源编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号
					cargo_name:'',//货物名称
					status:'',//状态
					payed_status:'',//支付状态
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					file_num:'',//磅单编号搜索
					item_name:'',//项目名称
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					start_time:"",//发车时间
                    end_time:"",//到货时间
					is_set_freight:'',

					creat_time_arr:[],
					is_set_insure:'',//保障服务费
					is_yufu:'',//是否垫资

					is_admin_pay_return:''//是否回款
				}
				this.date_value=''
				this.page.p = 1
				this.get_page_data()
			},

			//发货地省市县选择器
			ser_para_case_cities_change(event){
				this.form.case_prov=CodeToText[event[0]];
				this.form.case_city=CodeToText[event[1]];
				this.form.case_county=CodeToText[event[2]];
			},

			//到货地省市县选择器
			ser_para_aim_cities_change(event){
				this.form.aim_prov=CodeToText[event[0]];
				this.form.aim_city=CodeToText[event[1]];
				this.form.aim_county=CodeToText[event[2]];
			},

			//搜索
			page_ser(){
				//时间处理
				if (this.date_value) {
					this.form.start_time = parseInt(this.date_value[0] / 1000);
					this.form.end_time= parseInt(this.date_value[1] / 1000);
				} else {
					this.form.start_time = "";
					this.form.end_time = "";
				}
				if(this.creat_time_arr1){
					this.form.creat_time_arr[0] = parseInt(this.creat_time_arr1[0] / 1000);
				    this.form.creat_time_arr[1] = parseInt(this.creat_time_arr1[1] / 1000);
				}else{
					this.form.creat_time_arr = []
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num
				this.page.p =1

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){
				//加载中...
				if(this.list.loading){
					return;
				}
				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_by_shipper',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.code ==0){
						//加载完毕
						this.list.loading=false
						//总数
						this.list.total=data.msg.max
						this.list.pay_service_status = data.msg.pay_service_status
						//总运费
                        this.list.total_freight = data.msg.total.total_freight
						//已支付的总运费
						this.list.total_freight_has_payed = data.msg.total.total_freight_has_payed

						//总服务费
						this.list.total_service = data.msg.total.total_service
						//已支付的总服务费
						this.list.total_service_has_payed = data.msg.total.total_service_has_payed
						this.list.total_much = parseFloat(data.msg.total.total_freight)+parseFloat(data.msg.total.total_service);
						this.list.total_much = this.list.total_much.toFixed(2);
						this.list.total_cargo_weight = Number(data.msg.total.total_cargo_weight).toFixed(2)
						//总计数据
						this.list.total_give_driver = data.msg.total.total_give_driver
						this.list.total_give_truck_owner = data.msg.total.total_give_truck_owner
						this.list.total_insure = data.msg.total.total_insure
						//预处理
						for(let item of data.msg.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							item.yufu_apply_time_text = this.$my.other.totime(item.yufu_apply_time);
							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";

							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							/* 装货地其他数据(json) */
							item.case_other_obj=JSON.parse(item.case_other);

							//装货时间
							if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

							}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

							}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

							}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

								item.case_other_obj.case_date="未设置";
							}

							//发车时间
							if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

							}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

							}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

							}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有

								item.case_other_obj.start_time="未设置";
							}

							//发车时间是否强制校验
							switch(item.case_other_obj.start_time_is_check){
								case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
								case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
							}
							if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								item.case_other_obj.start_time_is_check_text=""
							}

							/* 卸货地其他数据(json) */
							item.aim_other_obj=JSON.parse(item.aim_other);

							//卸货时间
							if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

							}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

							}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

							}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有

								item.aim_other_obj.aim_date="未设置";
							}

							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}

							//锁定状态
							switch(item.lock_status){
								case '1':item.lock_status_text="已锁定";break;
								case '2':item.lock_status_text="未锁定";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需回程";break;
								case '2':item.is_need_return_text="不需回程";break;
							}

							//匹配方式
							switch(item.match_way){
								case '1':item.match_way_text="收款人抢单";break;
								case '2':item.match_way_text="指定后收款人接单";break;
								case '3':item.match_way_text="收款人竞价中标";break;
								case '4':item.match_way_text="收款人扫码接单";break;
								case '5':item.match_way_text="网点线下匹配";break;
								case '6':item.match_way_text="合作方匹配";break;
								case '7':item.match_way_text="后台匹配";break;
							}

							//运费总额
							if(item.cost_info.freight_total){
								item.cost_info.freight_total_text=item.cost_info.freight_total+"元";
							}else item.cost_info.freight_total_text="未设置";

							//已付总额
							item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+"元"

							//运费支付状态
							switch(item.payed_status){
								case '1':item.payed_status_text="未支付";break;
								case '2':item.payed_status_text="部分支付";break;
								case '3':item.payed_status_text="已支付";break;
							}
							if(!item.truck_info.truck_type_name){
								item.truck_info.truck_type_name ='无'
							}
							if(!item.truck_info.truck_type_name2){
								item.truck_info.truck_type_name2 ='无'
							}
							//货车类型
							item.truck_info.truck_type_name_text=item.truck_info.truck_type_name+'/'+item.truck_info.truck_type_name2

							//清空单个运单的支付记录
							item.payed_list=[];
						}

						//渲染
						this.list.data=data.msg.list

						//获取并置入支付记录
						this.get_and_show_payed_list();
						}else{
							this.list.loading=false
							this.list.data = []
						}
					}
				})
			},

			//获取并置入支付记录
			get_and_show_payed_list(){
				let list_in_page_obj={};
				let truck_tord_nums=[];
				for(var tord_item of this.list.data){
					list_in_page_obj[tord_item.truck_tord_num]=tord_item
					truck_tord_nums.push(tord_item.truck_tord_num);
				}

				//读取页面内运单的支付记录
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_shipper',
						truck_tord_nums:truck_tord_nums,
					},
					callback:(data)=>{
						//预处理
						for(let [index,item] of data.list.entries()){
							switch(item.pay_status){
								case '1':var pay_status_text="未支付";break;
								case '2':var pay_status_text="待审核";break;
								case '3':var pay_status_text="支付完成";break;
								case '4':var pay_status_text="支付失败";break;
								case '5':var pay_status_text="已撤销";break;
							}
							if(item.pay_status==4||item.pay_status==5){
								delete data.list[index]
								return
							}
							if(item.settled_time_of_driver!=0 &&item.settle_status_of_driver==3){
							
								item.settled_time_of_driver_text = '到账时间:'+this.$my.other.totime(item.settled_time_of_driver);
							}else if(item.this_settled_of_driver>0&&item.settle_status_of_driver==3){
								item.settled_time_of_driver_text='打款中'
							}
							if(item.settled_time_of_truck_owner!=0&&item.settle_status_of_truck_owner==3){
								
								item.settled_time_of_truck_owner_text = '到账时间:'+this.$my.other.totime(item.settled_time_of_truck_owner);
							}else if(item.this_settled_of_truck_owner>0&&item.settle_status_of_truck_owner==3){
								item.settled_time_of_truck_owner_text='打款中'
							}
							if(list_in_page_obj[item.truck_tord_num].payed_list.length<4){
								list_in_page_obj[item.truck_tord_num].payed_list.push({
									much:item.this_payed,
									pay_mark:item.pay_mark?item.pay_mark:"无备注",
									pay_status:pay_status_text,
									settled_time_of_truck_owner_text:item.settled_time_of_truck_owner_text,
									settled_time_of_driver_text:item.settled_time_of_driver_text
								})
							}
						}
					}
				});
			},
			sub_user(){
				this.loading = true;
				this.$my.net.req({
					data:{
						mod:'truck_sub_user',
						ctr:'sub_user_list_by_main_user',
						num:1000,
					},callback:(data)=>{
						this.loading = false;
						var sub_user_list = []
						data.list.forEach((item,index)=>{
							sub_user_list.push({
								value:item.sub_user_num,
								label:item.sub_user_info.tel+item.sub_user_info.name
							})
						})
						this.sub_user_list =sub_user_list
					}
				})
			},
			//获取可用搜索参数
			get_ser_para(){

				//尝试从缓存中取出
				let ser_para=this.$my.cache.get('cargo_creat_page_creat_para');

				//如果取出成功
				if(ser_para){
					this.ser_para.cargo_type=[...ser_para.cargo_type]
					this.ser_para.truck_type=[...ser_para.truck_type]
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'cargo_creat_para'
					},
					callback:(data)=>{

						//格式化车型数据
						let truck_type=truck_type_handle.for_el_cascader(data.truck_type);

						//缓存之
						this.$my.cache.set({
							cargo_creat_page_creat_para:{
								truck_type:truck_type,
								cargo_type:data.cargo_type
							}
						});

						//置入
						this.ser_para.cargo_type=[...data.cargo_type]
						this.ser_para.truck_type=[...truck_type]
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 400px);
	}
	.tab_height1{
		height:calc(100% - 60px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
		margin-left: 5px;
	}
	.freight_total{
		color:red;
		font-weight:600;
	}
	.text_huise{
		color:gray;
	}
	.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		span {
			margin-left: 20px;
		}
	}
	.btn{
		background-color:#FCF8ED;
		border: 1px solid #795A25;
		color:#795A25
	}
	.btn1{
		background-color:#795A25;
		border: none;
	}
	.red{
		color: red;
	}
</style>